import React from 'react'

import { Large } from './Large'

const MenuBar = () => (
  <>
    <Large />
  </>
)

export default MenuBar
